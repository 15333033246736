import React from "react"
import { Box } from "grommet"
import { Link, Layout, SEO } from "../components"

const Page = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Box pad="small">
        <Link to="/boilerplates/nav" label="test">
          Go to boilerplate nav page
        </Link>
      </Box>
      <Box pad="small">
        <Link to="/boilerplates" label="test">
          Go to boilerplate index page
        </Link>
      </Box>
      <Box pad="small">
        <Link to="/apps" label="test">
          Go to apps
        </Link>
      </Box>
      <Box pad="small">
        <Link to="/boilerplates/dashboard" label="test">
          Go to dashboard
        </Link>
      </Box>
      <Box pad="small">
        <Link to="/connections" label="test">
          Go to my connections
        </Link>
      </Box>
      <Box pad="small">
        <Link to="/clients" label="test">
          Go to my clients
        </Link>
      </Box>
      <Box pad="small">
        <Link to="/my-apps" label="test">
          Go to my apps
        </Link>
      </Box>
    </Layout>
  )
}

export default Page
